<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Sales Targets Reports</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-toolbar color="secondary" flat dense>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="options.startDate"
              label="Start Date"
              readonly
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="options.startDate"
            @input="updateRange"
          ></v-date-picker>
        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="options.endDate"
              label="End Date"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="options.endDate"
            @input="updateRange"
          ></v-date-picker>
        </v-menu>
        <v-autocomplete
          v-model="options.selectedItem"
          :items="salesEmployees"
          outlined
          dense
          attach
          chips
          clearable
          deletable-chips
          label="Select Sales Employees"
          item-text="SlpName"
          item-value="SlpCode"
          multiple
        ></v-autocomplete>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="targets"
              :search="search"
            >
              <!-- <template v-slot:item.action="{ item }">
                <v-btn icon :to="`/gpm/form-fields/${item.id}`">
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>
    
    <script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      targets: [],
      startDate: null,
      endDate: null,
      selectedItem: null,
      salesEmployees: [],
      options: { startDate: null, endDate: null, selectedItem: [] },
      headers: [
        { text: "Description", value: "Comment" },
        { text: "Employee", value: "SlpName" },
        { text: "Target value", value: "Tvalue" },
        { text: "Line total", value: "LineTotal" },
        { text: "CreditNotes", value: "CreditNoteLineTotal" },
        { text: "Achievement %", value: "line_total_percentage" },
        { text: "Period Start", value: "PeriodStart" },
        { text: "Period End", value: "PeriodEnd" },
        // { text: "Action", value: "action" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getTargets();
        this.defaultDates();
      },
      deep: true,
    },
  },

  methods: {
    defaultDates() {
      if (!this.options.startDate) {
        const today = new Date();
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        this.options.startDate = firstDayOfMonth.toISOString().substr(0, 10);
      }

      if (!this.options.endDate) {
        const lastDayOfMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        );
        this.options.endDate = lastDayOfMonth.toISOString().substr(0, 10);
      }
    },

    getEmployees() {
      const self = this;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.salesEmployees = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getTargets() {
      const self = this;
      this.loading = true;
      let url = `/getTargetsVsPerfomance`;
      if (this.options.startDate) {
        url += `?startdate=${this.options.startDate}`;
      }
      if (this.options.endDate) {
        url += `${url.includes("?") ? "&" : "?"}endate=${this.options.endDate}`;
      }
      if (this.options.selectedItem.length > 0) {
        url += `${
          url.includes("?") || url.includes("&") ? "&" : "?"
        }SlpCodes=${this.options.selectedItem
          .map((p) => `${encodeURIComponent(p)}`)
          .join(",")}`;
      }
      console.log(url);
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.targets = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    updateRange() {
      if (
        this.options.startDate &&
        this.options.endDate &&
        this.options.startDate > this.options.endDate
      ) {
        this.$refs.snackbar.show(
          "Start date cannot be greater than End date",
          "red"
        );

        this.options.endDate = "";
      }
    },
  },
  created() {
    this.getTargets();
    this.getEmployees();
    this.defaultDates();
  },
};
</script>
    
    <style scoped>
</style>